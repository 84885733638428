.AppHeader {
  background-color: #003974;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: 2em;

  border-bottom: solid 4px #b4975a;

  padding-left: 5em;

  .logoAndTitle {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    column-gap: 2em;

    img.Logo {
      display: block;
      height: 2em;
    }

    .AppTitle {
      color: #b4975a;
      font-size: 100%;
    }
  }


  @media (max-width: 900px) {

    .logoAndTitle {
      flex-direction: column;
    }

    .Decorations{
      width: 58vw;
      height: 14vw;
      background-position: left top;
    }

  }

  @media (min-width: 901px) {

    .logoAndTitle {
      flex-direction: row;
    }

    .Decorations{
      width: 29vw;
      height: 7vw;
      background-position: right top;
    }

  }

  .Decorations{
    background-image: url('../../img/graphics-in-top-panel.svg');
    background-size: auto 100%;

    background-repeat: no-repeat;
  }
}