.headerBackground{
  background-color: #c5c6c6;
}

.tableRow{
  margin: 0.5em 8em 0.5em 8em;
  //padding: 0.5em 0 0.5em 0;
  display: flex;
}

.tableRow.app {

  border-top: solid 1px #c5c6c6;
  border-right: solid 1px #c5c6c6;
  border-bottom: solid 1px #c5c6c6;
  border-left: none;
}

.colorCell{
  width: 1em;
}

.appNameCell{
  width: 30vw;
  padding: 0.5em 0.5em 0.5em 0.5em;
}

.statusCell{
  width: 30vw;
  padding: 0.5em 0.5em 0.5em 0.5em;
}

.lastCheckedCell{
  width: 30vw;
  padding: 0.5em 0.5em 0.5em 0.5em;

}


.statusOk {
  background-color: #70a130;
}

.statusWarn {
  background-color: #ffa217;
}

.statusError {
  background-color: #ff3a03;
}

.statusNoInfo {
  background-color: #c5c6c6;
}

